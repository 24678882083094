<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>Condifuración</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row align="center" justify="center" class="mt-12" v-if="isMobile()">
      <v-col md="10" class="d-flex justify-center">
        <v-card max-width="600px">
          <v-card-title> Impresoras </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="setting.bt"
                  prepend-inner-icon="mdi-bluetooth-audio"
                  append-outer-icon="mdi-magnify-scan"
                  :items="bt_devices"
                  label="Impresora Bluetooth"
                  item-text="name"
                  item-value="address"
                  return-object
                  outlined
                  hide-details
                  @click:append-outer="searchBT()"
                />
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  v-model="setting.wifi.address"
                  prepend-inner-icon="mdi-printer-wireless"
                  label="Impresora WIFI"
                  placeholder="https://192.168.0.90"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="setting.wifi.port"
                  label="Puerto"
                  placeholder="9000"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="setting.default"
                  :items="default_options"
                  label="Impresora predeterminiada"
                  return-object
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-btn @click="print_test()">Probar Código de barranas</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="success" @click="save_change()">
              Guardar Cambios
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-12" v-else>
      <v-col md="10" class="d-flex justify-center">
        <v-card flat height="100%" class="pa-3">
          <v-img height="80px" contain src="../assets/logo.png"></v-img>
          <v-card-title class="justify-center">
            <h3>Google Play Store</h3>
          </v-card-title>
          <v-card-title class="justify-center"
            >Descargar aplicativo para dispositivos Android, desde la tienda de
            aplicaciones.
          </v-card-title>
          <v-card-text>
            <v-img
              height="200px"
              contain
              src="../assets/googleplay.png"
            ></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <a
              href="https://play.google.com/store/apps/details?id=colombia.qr4.twa"
              class="mr-2"
              ><img
                src="../assets/google-play.png"
                width="200px"
                class="store-img"
            /></a>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { printDoc } from "../utils/escpos.js";
export default {
  components: {},
  data() {
    return {
      default_options: ["Bluetooth", "WIFI"],
      bt_devices: [],
      setting: {
        bt: { name: "", address: "" },
        wifi: { address: "", port: "" },
        default: "",
      },
    };
  },
  mounted() {
    var getSeetings = window.localStorage.getItem("qr4_printer");
    if (getSeetings) {
      this.setting = JSON.parse(
        this.CryptoJS.AES.decrypt(
          getSeetings,
          process.env.VUE_APP_QR4
        ).toString(this.CryptoJS.enc.Utf8)
      );
    }
    this.isMobile();
  },
  methods: {
    searchBT() {
      if (window.BT) {
        window.BT.showToast("Buscando dispositivos ...");
        this.bt_devices = JSON.parse(window.BT.getDeviceList());
      }
    },
    save_change() {
      window.BT.connect(this.setting.bt.address);
      const encryptedText = this.CryptoJS.AES.encrypt(
        JSON.stringify(this.setting),
        process.env.VUE_APP_QR4
      ).toString();
      window.localStorage.setItem("qr4_printer", encryptedText);
    },
    testBCReader() {
      if (window.android) {
        window.android.launchSettings();
        return true;
      }
      return false;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return navigator.userAgent;
      } else {
        return "";
      }
    },
    print_test() {
      if (this.setting.default === "WIFI") {
        if (window.LAN) {
          window.LAN.sendDataToDevice(
            this.setting.wifi.address,
            this.setting.wifi.portport,
            "test"
          );
        }
      } else {
        if (window.BT) {
          window.BT.sendDataToDevice(printDoc);
        }
      }
    },
  },
};
</script>

<style>
</style>