var cmds = {
    'ESC @': '\x1B\x40',
    'ESC D': '\x1B\x44',
    'LF': '\x0A',
    'FF': '\x0C',
    'HT': '\x09',
    'ESC !': '\x1B\x21',
    'ESC J': '\x1B\x4A',
    'ESC a': '\x1B\x61',
    'GS h': '\x1D\x68',
    'GS f': '\x1D\x66',
    'GS H': '\x1D\x48',
    'GS w': '\x1D\x77',
    'GS k': '\x1D\x6B',
    '(k': '\x1D\x6B',
    'NULL': '\x00',
    'CR': '\x0d'
};

function prt(cmd, par) {
    if (par) {
        return cmds[cmd] + par;
    } else {
        return cmds[cmd];
    }
}

function toHex(str) {
    var hex = "";
    for (var i = 0; i < str.length; i++) {
        hex += ' ' + str.charCodeAt(i).toString(16);
    }
    return hex;
}


export const printDoc = () => {

    var request = "";
    request = prt('ESC @');
    request += prt('LF');
    request += prt('ESC !', '01'); // Font A
    request += prt('ESC !', '08');
    request += prt('ESC !', '16');
    request += prt('ESC !', '32');
    request += prt('ESC a', '01'); //center
    request += toHex('StudioWeb67');
    request += prt('LF');

    request += prt('ESC !', '00'); // Font B
    request += prt('ESC a', '00'); //left
    request += prt('ESC D', '16 05 00'); //tab




    request += toHex('Product 1') + prt('HT') + toHex("500000") + prt('LF');


    request += toHex('Product 2') + prt('HT') + toHex("500000") + prt('LF');


    request += toHex('Product 3') + prt('HT') + toHex("500000") + prt('LF');
    //request += prt('ESC D','00 00'); //tab
    request += prt('ESC !', '00'); // Font A
    request += toHex("12345678901234567890123456789012345678901234567890") + prt('LF');
    request += prt('ESC !', '01'); // Font A
    request += toHex("12345678901234567890123456789012345678901234567890") + prt('LF');



    request += prt('LF');
    request += prt('ESC a', '01'); //center
    request += prt('ESC !', '01');
    request += prt('GS H', '02');
    request += prt('GS f', '01');
    request += prt('GS h', '50');
    request += prt('GS w', '03');
    request += prt('GS k', '05');
    request += toHex('12133713');
    request += prt('NULL');


    request += prt('LF');
    request += prt('LF');


    request = request.replace(/ /g, "");
    //WebSocketPrinter(request);
    return request;
}

// function WebSocketPrinter(msg) {
//     if ("WebSocket" in window) {
//         console.log("WebSocket is supported by your Browser!");
//         var ws = new WebSocket("ws://localhost:8765/echo");
//         ws.onopen = function () {
//             ws.send(msg);
//         };
//         ws.onmessage = function (evt) {
//             var received_msg = evt.data;
//             console.log(received_msg);
//         };
//         ws.onclose = function () {
//             console.log("Connection is closed...");
//         };
//     } else {
//         console.log("WebSocket NOT supported by your Browser!");
//     }
// }